












































































import { Auth } from "@/mixins/auth";
import { ContestEntry } from "@/models/contest";
import { Roles } from "@/models/user/roles";
import ContestService from "@/services/contest_service";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ContestPdf from "@/components/contest/ContestPdf.vue";
import Header from "@/components/designer/header/Header.vue";
import PageHeadline from "@/components/designer/reusable/PageHeadline.vue";
import AccordionSection from "@/components/designer/reusable/AccordionSection.vue";

@Component({
  components: {
    ContestPdf,
    Header,
    PageHeadline,
    AccordionSection
  },
})
export default class ContestAdmin extends mixins(Auth) {
  protected contestService = new ContestService();
  protected Roles = Roles;
  protected entries: ContestEntry[] = [];

  async created(): Promise<void> {
    await this.getContestEntries();
  }

  protected async deleteEntry(id: string): Promise<void> {
    try {
      await this.contestService.deleteEntry(id);
      await this.getContestEntries();
    } catch (err) {
      console.log(err);
    }
  }

  protected async getContestEntries(): Promise<void> {
    try {
      this.entries = await this.contestService.getEntries();
    } catch (err) {
      console.log(err);
    }
  }

  get appUrl(): string {
    return process.env.VUE_APP_URL;
  }

  protected formatTitle(title: string, part: string): string {
    let result = "";
    if (title.indexOf(" ")) {
      if (part == "a") {
        result = title.substr(0, title.indexOf(" "));
      } else {
        result = title.substr(title.indexOf(" ") + 1);
      }
    } else {
      return title;
    }

    return result;
  }
}
