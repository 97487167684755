













































import { ContestEntry } from "@/models/contest";
import { Component, Prop, Vue } from "vue-property-decorator";
import VueHtml2pdf from "vue-html2pdf";
import QrcodeVue from "qrcode.vue";
@Component({
  components: {
    VueHtml2pdf,
    QrcodeVue,
  },
})
export default class ContestPdf extends Vue {
  @Prop() entry!: ContestEntry;

  get appUrl(): string {
    return process.env.VUE_APP_URL;
  }

  protected downloadPdf(id: string): void {
    (this.$refs[id]! as any).generatePdf();
  }

    public makeSentenceCase(sentence: string) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }
}
